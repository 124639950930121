<template>
  <div class="agreement">
    <div class="header">
      <div class="left" @click="goback">
        <van-icon name="arrow-left" class="icon" />
      </div>
      <div class="title">课程协议</div>
    </div>
    <div v-html="detail"></div>
  </div>
</template>

<script>
import { getAgressment } from "@/api/order.js";
export default {
  data() {
    return {
      detail: null,
    };
  },
  created() {
    this.getdetail();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    getdetail() {
      getAgressment(this.$route.query.coursePackageId).then((res) => {
        this.detail = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
    }
  }
  .title {
    width: 80%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
</style>

